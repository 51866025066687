exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-case-study-augmented-shopping-js": () => import("./../../../src/pages/case_study_augmented_shopping.js" /* webpackChunkName: "component---src-pages-case-study-augmented-shopping-js" */),
  "component---src-pages-case-study-design-system-js": () => import("./../../../src/pages/case_study_design_system.js" /* webpackChunkName: "component---src-pages-case-study-design-system-js" */),
  "component---src-pages-case-study-forex-trading-js": () => import("./../../../src/pages/case_study_forex_trading.js" /* webpackChunkName: "component---src-pages-case-study-forex-trading-js" */),
  "component---src-pages-case-study-mobile-vision-js": () => import("./../../../src/pages/case_study_mobile_vision.js" /* webpackChunkName: "component---src-pages-case-study-mobile-vision-js" */),
  "component---src-pages-case-study-pickup-delivery-js": () => import("./../../../src/pages/case_study_pickup_delivery.js" /* webpackChunkName: "component---src-pages-case-study-pickup-delivery-js" */),
  "component---src-pages-case-study-web-trading-js": () => import("./../../../src/pages/case_study_web_trading.js" /* webpackChunkName: "component---src-pages-case-study-web-trading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-projects-archive-js": () => import("./../../../src/pages/projects_archive.js" /* webpackChunkName: "component---src-pages-projects-archive-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-note-template-js": () => import("./../../../src/templates/noteTemplate.js" /* webpackChunkName: "component---src-templates-note-template-js" */)
}

